define('deputee-office/models/zs-passport/zs-passport-header', ['exports', 'ember-data', 'deputee-office/models/base', 'ember-flexberry-data', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _emberFlexberryData, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    IsAccepted: _emberData['default'].attr('boolean'),
    Title: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    Name: _emberData['default'].attr('string'),
    Subject: _emberData['default'].attr('string'),
    HeadCommittee: _emberData['default'].attr('string'),
    ProjectLegalActFiles: _emberData['default'].hasMany('zs-passport/zs-passport-file', { async: false }),
    Tabs: _emberData['default'].attr('json-data'),
    CurrentTab: _emberData['default'].attr('json-data'),
    EditTime: _emberData['default'].attr('string'),
    HiddenText: _emberData['default'].attr('string'),

    TabListObject: _ember['default'].computed('Tabs', 'CurrentTab', 'Passport', function () {
      var tabs = this.get('Tabs');
      var currentTab = this.get('ActualCurrentTab');
      if (tabs) {
        var questionsTab = this.get('Passport.AddInfo');
        if (questionsTab) {
          if (!tabs.findBy('Name', questionsTab.get('Name'))) {
            tabs.pushObject(questionsTab);
          }
        }

        return tabs.map(function (tab) {
          tab.set('IsCurrent', tab.get('Name') === currentTab.get('Name'));
          return tab;
        }).sortBy('OrderNum');
      }
    }),

    /**
     * Определяет текущую вкладку, проверяя что пришедшая с сервера есть в списке всех вкладок.
     * Если ее в списке нет, ставит текущей вкладкой последнюю из пришедших с сервера.
    */
    ActualCurrentTab: _ember['default'].computed('CurrentTab', 'Tabs', function () {
      var currentTab = this.get('CurrentTab');
      var tabs = this.get('Tabs');
      var actualTab = undefined;
      if (currentTab) {
        actualTab = tabs.findBy('Name', currentTab.get('Name'));
      }

      return actualTab ? actualTab : tabs.get('lastObject');
    })
  });

  Model.defineProjection('zs-passport-header-full', 'zs-passport/zs-passport-header', {
    IsAccepted: _emberFlexberryData.Projection.attr('IsAccepted'),
    Title: _emberFlexberryData.Projection.attr('Title'),
    Name: _emberFlexberryData.Projection.attr('Name'),
    Subject: _emberFlexberryData.Projection.attr('Subject'),
    HeadCommittee: _emberFlexberryData.Projection.attr('HeadCommittee'),
    ProjectLegalActFiles: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-file', 'ProjectLegalActFiles', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url'),
      OrderNum: _emberFlexberryData.Projection.attr('OrderNum')
    }),
    Tabs: _emberFlexberryData.Projection.attr('Tabs'),
    CurrentTab: _emberFlexberryData.Projection.attr('CurrentTab')
  });

  exports['default'] = Model;
});